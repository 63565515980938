import React, { useState, useEffect } from "react";
import './profile.css';
import { Link,useParams,useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../sidebar/sidebar";

// ユーザータイプを定義
type UserType = {
    id: number;
    username: string;
    description: string;
    image: string;
    sports: string;
    position: string;
    job: string;
};
// Movieの  
interface Movie {
    videotitle: string;
    video: File | null; //ファイル転送のため
};

//　XXXX: 定義の仕方見直しひつようあり
// アーカイブタイプを定義
interface ArchiveType {
    user: UserType;
    archiveId: number;
    username: string;
    title: string;
    subtitle: string;
    context: string;
    movies: Movie[];
    isPrivate: boolean;
    created_at: string;
    archive_count: number;
    applause_count: number;
};

function Profile() {
    // 検索
    const [searchQuery, setSearchQuery] = useState('');
    /* ユーザーのデータ取得 */
    const [userData, setUserData] = useState({
        displayname: '',
        context: '',
        profileImage: '',
        sports: '',
        position: '',
        job: '',
        archive_count: '',
        applause_count: '',
    });
    const navigate = useNavigate();

    
    /* アーカイブのデータ取得 */
    const [archives, setArchives] = useState<ArchiveType[]>([]);

    // URLパラメータからユーザー名を取得
    const { username } = useParams();
    const [isCurrentUser, setIsCurrentUser] = useState(false);

    ////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////

    // 拍手されたアーカイブの型を持つ配列
    const [applaudedArchives, setApplaudedArchives] = useState<ArchiveType[]>([]);

    // 各アーカイブの拍手状態を管理するオブジェクト
    const [applauseStatus, setApplauseStatus] = useState<{ [key: number]: boolean }>({});

    // 拍手の状態を初期化する関数
    const setInitialApplauseStatus = (applaudedArchives: ArchiveType[]) => {
        const initialStatus: { [key: number]: boolean } = {};
            applaudedArchives.forEach((archive: ArchiveType) => {
                initialStatus[archive.archiveId] = true; // ここで拍手済みと仮定
            });
        setApplauseStatus(initialStatus);
    };

    ////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////
    
    // ユーザーのデータ取得
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    navigate('/login');
                    return;
                }
                // トークンをヘッダーに設定
                const config = {
                    headers: {
                        Authorization: `Token ${token}`
                       
                    },
                    // 検索クエリをパラメータに含める
                    params: { search: searchQuery }
                };

                // ここの二つのURLはなんか違うから変更する必要あり
                const profileUrl = username 
                    ? `https://playarchive-backend.onrender.com/accounts/${username}/profile/` 
                    : 'https://playarchive-backend.onrender.com/accounts/profile/';

                const user_response = await axios.get(profileUrl, config);
                setUserData(user_response.data);


                const loggedInUsername = localStorage.getItem('username');

                // 現在ログインしているユーザーのIDとプロフィールページのユーザーのIDの比較
                const isCurrentUser = loggedInUsername === user_response.data.username;

                setIsCurrentUser(isCurrentUser);
                const archivesUrl = username 

                ? `https://playarchive-backend.onrender.com/archive/${username}/archives/` 
                : 'https://playarchive-backend.onrender.com/archive/user/archives/';

                const archive_responce = await axios.get(archivesUrl, config);
                setArchives(archive_responce.data);
  
                // 拍手したアーカイブのデータを取得するURL
                const applaudedArchivesUrl = `https://playarchive-backend.onrender.com/archive/user/applause/`;
                // 拍手したアーカイブのデータを取得
                const fetchApplaudedArchives = async () => {
                    try {
                        // 拍手したアーカイブのデータを取得
                        const applaudedResponse = await axios.get<ArchiveType[]>(applaudedArchivesUrl, config);
                        setApplaudedArchives(applaudedResponse.data);
                        setInitialApplauseStatus(applaudedResponse.data); // 拍手の状態を初期化
                    } catch (error) {
                        // エラーハンドリング
                        console.error("アーカイブの取得に失敗しました", error);
                    }
                };

                fetchApplaudedArchives();

            } catch (error) {
                alert('データの取得に失敗しました')
            }
        };
        fetchUserData();
    }, [searchQuery,navigate,username]);

    ////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////

    // ステートを用いて表示するデータを管理します
    const [showArchives, setShowArchives] = useState(true);
    // 検索クエリを変更するハンドラー
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    }
    
    // フォロー状態を追跡
    const [isFollowing, setIsFollowing] = useState(false); 

    // Follow機能
    const handleFollow = async () => {
        try{
            const token = localStorage.getItem('token');
                await axios.post(`https://playarchive-backend.onrender.com/follow/${username}/`, {}, {
                headers: {
                    Authorization: `Token ${token}`
                }
            });
            // フォロー状態を更新
            setIsFollowing(!isFollowing); 
        } catch (error) {
            alert('フォローに失敗しました')
        }
    };

    // 星をクリックしたときに呼ばれる関数
    const handleApplause = async (archive:ArchiveType) => {
        try {
            const token = localStorage.getItem('token');
            const url = `https://playarchive-backend.onrender.com/archive/${archive.user.username}/archives/${archive.title}/applause/`;

            const response = await axios.post(url, {}, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            });
            // 拍手の状態を更新
            setApplauseStatus(prevStatus => ({
                ...prevStatus,
                [archive.archiveId]: !prevStatus[archive.archiveId]
            }));
            // バックエンドから返ってきたデータをセット
            alert(response.data.message);
        } catch (error) {
            // ユーザーにエラーメッセージを表示
            alert('拍手の送信に失敗しました。もう一度お試しください。');
        }
    };
    ////////////////////////////

    

    return (
        <div className='profile'>
            <SideBar/>
            <div className="w-full pt-10 px-4 sm:px-6 md:px-8 lg:ps-72">
                <header>
                <div className="account">
                    <div className="account-left">
                        <div className="account-image">
                        {/*ストレージに写真や動画を置くためパスの変更 */}
                        <img 
                            src={userData.profileImage}
                            alt={userData.displayname}
                            style={{ height: '125px', borderRadius: '50%', objectFit: 'cover' }}
                        />
                        </div>
                        <div className="account-title">
                            <h4>Post</h4>
                            <div className="account-post">
                                <div className="account-post-left">
                                    <h2>{userData.archive_count}</h2>
                                    <div className="account-post-archive">
                                        <p>Archive</p>
                                    </div>
                                </div>
                                <div className="account-post-right">
                                    <h2>{userData.applause_count}</h2>
                                    <div className="account-post-playbook">
                                        <p>Star</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="account-wrapper">
                        <div className="account-center">
                                <div className="account-name">
                                <h2>{userData.displayname}</h2>
                                </div>
                                <div className="account-description">
                                <p>{userData.context}</p>
                                </div>
                        </div>
                        <div className="account-right">
                            <div className="account-sports">
                                <p className="title">Sports</p>
                                <h5 className="info bold underline">{userData.sports}</h5>
                            </div>
                            <div className="account-position">
                                <p className="title">Position</p>
                                <p className="info bold underline">{userData.position}</p>
                            </div>
                            <div className="account-job">
                                <p className="title">Job</p>
                                <p className="info bold underline">{userData.job}</p>
                            </div>
                        </div>
                    </div>
                </div>
            
                {isCurrentUser ? (
                    <div className="profile-edit">
                        <Link to="/profileedit" className="profile-edit-button">
                            <h2>プロフィールを編集する</h2>
                        </Link>
                    </div>
                ) : (
                    <div className="profile-edit">
                        <button className="profile-edit-button" onClick={handleFollow}>
                            {isFollowing ? 'フォロー解除' : 'フォローする'}
                        </button>
                    </div>
                )}
                <div className="account-data">
                    {/* アーカイブとプレイブックの切り替えボタン */}
                    <div className="toggle-buttons">
                        <button className={`tab-button ${showArchives ? "active" : ""}`} onClick={() => setShowArchives(true)}>
                            自分のアーカイブ
                        </button>
                        <button className={`tab-button ${!showArchives ? "active" : ""}`} onClick={() => setShowArchives(false)}>
                            拍手したアーカイブ
                        </button>
                    </div>

                    {/* アーカイブまたはプレイブックの表示 */}
                    <div className="data-display">
                        {showArchives ? (
                            <div className="archives">
                                {/* 処理が変わるため別々の検索機能 */}
                                <div className="search-sort-new-container">
                                    <div className="search-box">
                                    <input
                                        type="text"
                                        placeholder="Archiveを検索する"
                                        className="playarchive-search-input-text"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />
                                    </div>
                                    <button className="sort-button">Sort</button>
                                    <div className="search-sort-new-container-new">
                                        <Link to="/createarchive"style={{ textDecoration: 'none', color: 'white' }} className="new-button">New</Link>
                                    </div>
                                </div>
                                <div className="account-archive">
                                    {archives.map((archive,index) => (
                                        <div key={archive.username} className="archive-item">
                                             <div className="archive-content">
                                                {/* 下線を取り除くためstyleを使用 */}
                                                <Link to={username ? `/playarchive/${username}/${archive.title}` : `/playarchive/${archive.title}`} style={{ textDecoration: 'none', color: 'white' }}>
                                                    <h2 className="playarchive-title">{archive.title}</h2>
                                                </Link>
                                                <div className="archive-details">
                                                    <span className={`playarchive-status-1 ${archive.isPrivate ? 'private' : 'public'}`}>
                                                        {archive.isPrivate ? 'Private' : 'Public'}
                                                    </span>
                                                    <span className="archive-description">{archive.context}</span>
                                                   
                                                </div>
                                            </div>
                                            <div className="archive-star" onClick={() => handleApplause(archive)}>
                                                {applauseStatus[archive.archiveId] ? '★' : '☆'}
                                            </div>
                                        </div>
                                    ))}
                                </div>                           
                            </div>
                        ) : (
                            <div className="archives">
                                {/* いいねしたアーカイブ */}
                                
                                <div className="account-archive">
                                    {applaudedArchives.map((archive) => (
                                        <div key={archive.archiveId} className="archive-item">
                                            <div className="archive-content">
                                                <h3 className="archive-title">{archive.title}</h3>
                                                <div className="archive-details">                                    
                                                    <span className="archive-description">{archive.context}</span>
                                                    
                                                </div>
                                            </div>
                                            <div className="archive-star" onClick={() => handleApplause(archive)}>
                                                {applauseStatus[archive.archiveId] ? '★' : '☆'}
                                            </div>
                                        </div>
                                    ))}
                                </div>                           
                        </div>
                        )}
                    </div>
                </div>

                </header>
            </div>

        </div>
    );
    
}

export default Profile;