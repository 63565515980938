import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/home/home';
import Profile from './components/profile/profile';
import PlayArchive from './components/playarchive/playarchive';
import PlayArchiveDetail from './components/playarchivedetail/playarchivedetail';
import CreateArchive from './components/createarchive/createarchive';
import Login from './components/login/login';
import Register from './components/register/register';
import ProfileEdit from './components/profileedit/profileedit';
import PlayArchiveEdit from './components/playarchivedit/playarchiveedit';
import UserProfile from './components/userprofile/userprodfile';
import PasswordForget from './components/paaswordforget/passwordforget';
import ArchiveSearch from './components/archivesearch/archivesearch';
import HomePage from './components/homepage/home/home';
import VerifyEmail from './components/verifyEmail/verifyemail';
import Contact from './components/homepage/contact/contact';
import Howtouse from './components/homepage/howtouse/howtouse';

function App() {
  return (
     <div className = "App">
      <BrowserRouter basename='/'>
            <Routes>
              <Route path='/' element={<HomePage/>}/>
              <Route path="/home" element={<Home />}/>
              <Route path="/contact" element={<Contact />}/>
              <Route path="/how-to-use" element={<Howtouse />}/>
              <Route path="/login" element={<Login />}/>
              <Route path="/register" element={<Register />}/>
              <Route path="/verify/:token" element={<VerifyEmail />} />
              <Route path="/profile" element={<Profile />}/>
              <Route path="/profile/:username" element={<UserProfile />} />
              <Route path="/playarchive" element={<PlayArchive />}/>
              <Route path="/playarchive/:title" element={<PlayArchiveDetail />}/>
              <Route path="/playarchive/:username/:title" element={<PlayArchiveDetail />}/>
              <Route path="/playarchive/:title/edit" element={<PlayArchiveEdit />}/>
              <Route path="/createarchive" element={<CreateArchive />}/>
              <Route path="/profileedit" element={<ProfileEdit />}/>
              <Route path="/passwordforget" element={<PasswordForget />}/>
              <Route path="/archivesearch" element={<ArchiveSearch />}/>
            </Routes>
      </BrowserRouter>     
     </div>
  );
}

export default App;
