import React, {  useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";

function SideBar() {
    const [isOpen, setIsOpen] = useState(false);
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const toggleAccordion = () => {
        setIsAccordionOpen(!isAccordionOpen); // アコーディオンの状態をトグルする
    };

    // ログアウト処理
    const handleLogout = async () => {
        try{
             // ログアウトリクエスト処理
             const response = await axios.post('https://playarchive-backend.onrender.com/accounts/logout/', {}, {
                // Token確認
                headers: {
                  'Authorization': `Token ${localStorage.getItem('token')}`
                }
            });
   
            
            if (response.status === 200) {
                // ログアウト成功時
                // トークンを削除してログインページに遷移
                localStorage.removeItem('token');
                alert('ログアウトしました');
    
                window.location.href = '/login'; // ログインページにリダイレクト
            }
        } catch (error) {
           alert('ログアウトに失敗しました。もう一度お試しください。')
        }
    };

    // ユーザー削除処理
    const handleUserDelete = async () => {
        if (window.confirm('本当に削除しますか？,この操作は元に戻せません。')) {
            try{
                const token = localStorage.getItem('token');
                // トークンがない場合
                if (!token){
                    alert('ログインしてください');
                    return;
                }

                const response = await axios.delete('https://playarchive-backend.onrender.com/accounts/delete/', {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                });

                if (response.status === 204) {
                    // ユーザーが削除されたので、トークンを削除してログインページにリダイレクトします。
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }

            } catch (error) {
                alert('アカウントの削除に失敗しました。もう一度お試しください。')
            }
        }
    };


    return(
            <body className="bg-gray-50">
                <div className="sticky top-0 inset-x-0 z-20 bg-white border-y px-4 sm:px-6 md:px-8 lg:hidden dark:bg-gray-800 dark:border-gray-700">
                    <div className="flex items-center py-4">
                    <button type="button" onClick={toggleSidebar} className="text-gray-500 hover:text-gray-600" data-hs-overlay="#application-sidebar-brand" aria-controls="application-sidebar-brand" aria-label="Toggle navigation">
                            <span className="sr-only">Toggle Navigation</span>
                            <svg className="w-5 h-5" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                    </button>

                         
                    <ol className="ms-3 flex items-center whitespace-nowrap" aria-label="Breadcrumb">
                        <li className="flex items-center text-sm text-gray-800 dark:text-gray-400">
                        PlayArchive
                        <svg className="flex-shrink-0 mx-3 overflow-visible h-2.5 w-2.5 text-gray-400 dark:text-gray-600" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 1L10.6869 7.16086C10.8637 7.35239 10.8637 7.64761 10.6869 7.83914L5 14" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                        </li>
                        <li className="text-sm font-semibold text-gray-800 truncate dark:text-gray-400" aria-current="page">
                        Dashboard
                        </li>
                    </ol>

                    </div>
                </div>

                {/* Sidebar */}
                {isOpen && (
                    <div
                        className="fixed inset-0 bg-black opacity-50 z-50"
                        onClick={() => setIsOpen(false)}
                    ></div>
                )}

                <div id="application-sidebar-brand" className={`hs-overlay fixed inset-y-0 left-0 z-[100] w-64 bg-blue-700 pt-7 pb-10 overflow-y-auto transition-all transform ${
                    isOpen ? 'translate-x-0' : '-translate-x-full'
                    } lg:block lg:translate-x-0 lg:right-auto lg:bottom-0`}
                >
                       
                     <div className="px-6">
                        <a className="flex-none text-xl font-semibold text-white" href="/" aria-label="Brand">
                            PlayArchive
                        </a>
                    </div>
                    <nav className="hs-accordion-group p-6 w-full flex flex-col flex-wrap" data-hs-accordion-always-open>
                        <ul className="space-y-1.5">
                            <li>
                                <NavLink 
                                        to="/home" 
                                        className={({ isActive }) => 
                                        isActive ? "flex items-center gap-x-3 py-2 px-2.5 bg-blue-600 text-sm text-white rounded-lg" : 
                                        "flex items-center gap-x-3 py-2 px-2.5 text-sm text-white rounded-lg hover:bg-blue-600"
                                        }>
                                        <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"/><polyline points="9 22 9 12 15 12 15 22"/></svg>
                                        ホーム
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/playarchive"
                                        className={({ isActive }) => 
                                        isActive ? "flex items-center gap-x-3 py-2 px-2.5 bg-blue-600 text-sm text-white rounded-lg" : 
                                        "flex items-center gap-x-3 py-2 px-2.5 text-sm text-white rounded-lg hover:bg-blue-600"
                                        }>
                                    
                                        <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect width="18" height="18" x="3" y="4" rx="2" ry="2"/><line x1="16" x2="16" y1="2" y2="6"/><line x1="8" x2="8" y1="2" y2="6"/><line x1="3" x2="21" y1="10" y2="10"/><path d="M8 14h.01"/><path d="M12 14h.01"/><path d="M16 14h.01"/><path d="M8 18h.01"/><path d="M12 18h.01"/><path d="M16 18h.01"/></svg>
                                        アーカイブ
                                    
                                    </NavLink>
                                </li>
                                
                                <li>
                                    <NavLink to="/createarchive"
                                    className={({ isActive }) => 
                                    isActive ? "flex items-center gap-x-3 py-2 px-2.5 bg-blue-600 text-sm text-white rounded-lg" : 
                                    "flex items-center gap-x-3 py-2 px-2.5 text-sm text-white rounded-lg hover:bg-blue-600"
                                    }>
                                        
                                        <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"/><path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"/></svg>
                                        アーカイブ作成
                                        
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/archivesearch"
                                        className={({ isActive }) => 
                                        isActive ? "flex items-center gap-x-3 py-2 px-2.5 bg-blue-600 text-sm text-white rounded-lg" : 
                                        "flex items-center gap-x-3 py-2 px-2.5 text-sm text-white rounded-lg hover:bg-blue-600"
                                        }>
                                    
                                        <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect width="18" height="18" x="3" y="4" rx="2" ry="2"/><line x1="16" x2="16" y1="2" y2="6"/><line x1="8" x2="8" y1="2" y2="6"/><line x1="3" x2="21" y1="10" y2="10"/><path d="M8 14h.01"/><path d="M12 14h.01"/><path d="M16 14h.01"/><path d="M8 18h.01"/><path d="M12 18h.01"/><path d="M16 18h.01"/></svg>
                                        検索
                                    
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink to="/profile"
                                    className={({ isActive }) => 
                                    isActive ? "flex items-center gap-x-3 py-2 px-2.5 bg-blue-600 text-sm text-white rounded-lg" : 
                                    "flex items-center gap-x-3 py-2 px-2.5 text-sm text-white rounded-lg hover:bg-blue-600"
                                    }>
                                    
                                    <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"/><circle cx="9" cy="7" r="4"/><path d="M22 21v-2a4 4 0 0 0-3-3.87"/><path d="M16 3.13a4 4 0 0 1 0 7.75"/></svg>
                                    プロフィール
                                    
                                    </NavLink>
                                </li>
                                
                            <li className="hs-accordion">
                                <button
                                    type="button"
                                    className={`w-full text-start flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-white rounded-lg hover:bg-blue-600 ${
                                    isAccordionOpen ? 'hs-accordion-active:text-white hs-accordion-active:hover:bg-transparent' : ''
                                    }`}
                                    onClick={toggleAccordion} // ボタンがクリックされたときに実行
                                >
                                    <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"/><circle cx="9" cy="7" r="4"/><path d="M22 21v-2a4 4 0 0 0-3-3.87"/><path d="M16 3.13a4 4 0 0 1 0 7.75"/></svg>
                                  
                                    ユーザー

                                    {/* アコーディオンが開いているかどうかによって表示を切り替えるアイコン */}
                                    {isAccordionOpen ? (
                                    <svg className="ms-auto block w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m18 15-6-6-6 6" />
                                    </svg>
                                    ) : (
                                    <svg className="ms-auto block w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m6 9 6 6 6-6" />
                                    </svg>
                                    )}
                                </button>

                                {/* アコーディオンの内容 */}
                                {isAccordionOpen && (
                                    <div className="w-full overflow-hidden transition-[height] duration-300">
                                    <ul className="pt-2 ps-2">
                                        {/* リンクアイテム */}
                                        <li>
                                            <button className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-white rounded-lg hover:bg-blue-600" onClick={handleLogout}>
                                                ログアウト
                                            </button>
                                        </li>
                                        <li>
                                            <button className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-white rounded-lg hover:bg-blue-600" onClick={handleUserDelete}>
                                                ユーザー削除
                                            </button>
                                        </li>
                                    </ul>
                                    </div>
                                )}
                            </li>
                            <li>
                                    <NavLink to="/how-to-use"
                                    className={({ isActive }) => 
                                    isActive ? "flex items-center gap-x-3 py-2 px-2.5 bg-blue-600 text-sm text-white rounded-lg" : 
                                    "flex items-center gap-x-3 py-2 px-2.5 text-sm text-white rounded-lg hover:bg-blue-600"
                                    }>
                                    
                                    <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"/><circle cx="9" cy="7" r="4"/><path d="M22 21v-2a4 4 0 0 0-3-3.87"/><path d="M16 3.13a4 4 0 0 1 0 7.75"/></svg>
                                    使い方
                                    
                                    </NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
                   
        </body>

    );
}

export default SideBar;