import React, { useState } from 'react';
import './register.css'
import axios, { AxiosError } from 'axios';

// エラーレスポンスの方
interface ErrorResponse {
  [key: string]: string[];
}

const Register = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    displayname: '',
    password: '',
    password_confirm: '',
  });
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // ローディング状態を管理するための状態
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formData.password !== formData.password_confirm) {
      setErrorMessage('パスワードが一致しません');
      return;
    }
    try {
      // リクエスト前にローディングをtrueに設定
      setLoading(true);

      await axios.post('https://playarchive-backend.onrender.com/accounts/register/', formData);
      alert('認証のためメールを送信しました。確認をお願いします。');
    } catch (err) {
      if (axios.isAxiosError(err)){
        const serverError = err as AxiosError<ErrorResponse>;
        if (serverError && serverError.response){
          const errors = serverError.response.data;
          let errorMessage = '';
          for(const key in errors){
            // 'errors[key]' は string[] 型なので、join() を使用して文字列に変換
            errorMessage += `${errors[key].join(' ')} `; 
          }
          setErrorMessage(errorMessage.trim());
        }
      } else {
        setErrorMessage('登録に失敗しました')
      }
    } finally {
      // リクエスト完了後にローディングをfalseに設定
      setLoading(false);
    }
  };
  
  return (
    <div className="">
      <html className="h-full">
        <body className="bg-gray-100 flex h-full items-center py-16">
          <main className="w-full max-w-md mx-auto p-6">
            <div className="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm">
              <div className="p-4 sm:p-7">
                <div className="text-center">
                  <h1 className="block text-2xl font-bold text-gray-800 ">アカウント作成</h1>
                  <p className="mt-2 text-sm text-gray-600 ">
                    アカウントをお持ちですか？
                    <a className="text-blue-600 decoration-2 hover:underline font-medium" href="/login">
                      ログイン
                    </a>
                  </p>
                </div>

                <div className="mt-5">
                 
                  <form onSubmit={handleSubmit}>
                    <div className="grid gap-y-4">

                      <div>
                        <label htmlFor="username" className="block text-sm mb-2 ">UserId</label>
                        <div className="relative">
                          <input type="text" id="username" name="username" value={formData.username} onChange={handleChange}className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " required aria-describedby="email-error"/>
                          <div className="hidden absolute inset-y-0 end-0 flex items-center pointer-events-none pe-3">
                            <svg className="h-5 w-5 text-red-500" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" aria-hidden="true">
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                            </svg>
                          </div>
                        </div>
                        <p className="hidden text-xs text-red-600 mt-2" id="email-error">Please include a valid email address so we can get back to you</p>
                      </div>
                      <div>
                        <label htmlFor="email" className="block text-sm mb-2 ">メールアドレス</label>
                        <div className="relative">
                          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange}className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " required aria-describedby="email-error"/>
                          <div className="hidden absolute inset-y-0 end-0 flex items-center pointer-events-none pe-3">
                            <svg className="h-5 w-5 text-red-500" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" aria-hidden="true">
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                            </svg>
                          </div>
                        </div>
                        <p className="hidden text-xs text-red-600 mt-2" id="email-error">Please include a valid email address so we can get back to you</p>
                      </div>
                      <div>
                        <label htmlFor="displayname" className="block text-sm mb-2 ">名前</label>
                        <div className="relative">
                          <input type="text" id="displayname" name="displayname"  value={formData.displayname} onChange={handleChange}className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " required aria-describedby="email-error"/>
                          <div className="hidden absolute inset-y-0 end-0 flex items-center pointer-events-none pe-3">
                            <svg className="h-5 w-5 text-red-500" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" aria-hidden="true">
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                            </svg>
                          </div>
                        </div>
                        <p className="hidden text-xs text-red-600 mt-2" id="email-error">Please include a valid email address so we can get back to you</p>
                      </div>

                      <div>
                        <label htmlFor="password" className="block text-sm mb-2 ">パスワード</label>
                        <div className="relative">
                          <input type="password" id="password" name="password" value={formData.password} onChange={handleChange}className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " required aria-describedby="password-error"/>
                          <div className="hidden absolute inset-y-0 end-0 flex items-center pointer-events-none pe-3">
                            <svg className="h-5 w-5 text-red-500" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" aria-hidden="true">
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                            </svg>
                          </div>
                        </div>
                        <p className="hidden text-xs text-red-600 mt-2" id="password-error">8+ characters required</p>
                      </div>

                      <div>
                        <label htmlFor="confirm-password" className="block text-sm mb-2 ">確認パスワード</label>
                        <div className="relative">
                          <input type="password" id="password_confirm" name="password_confirm" value={formData.password_confirm}onChange={handleChange}className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " required aria-describedby="confirm-password-error"/>
                          <div className="hidden absolute inset-y-0 end-0 flex items-center pointer-events-none pe-3">
                            <svg className="h-5 w-5 text-red-500" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" aria-hidden="true">
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                            </svg>
                          </div>
                        </div>
                        <p className="hidden text-xs text-red-600 mt-2" id="confirm-password-error">Password does not match the password</p>
                      </div>
                      {errorMessage && (
                        <div className="bg-red-50 border-s-4 border-red-500 p-4 " role="alert">
                          <div className="flex">
                            <div className="flex-shrink-0">
                            
                              <span className="inline-flex justify-center items-center w-8 h-8 rounded-full border-4 border-red-100 bg-red-200 text-red-800 https://playarchive-backend.onrender.com/bg-red-800 ">
                                <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
                              </span>
              
                            </div>
                            <div className="ms-3">
                              <h3 className="text-gray-800 font-semibold">
                                注意
                              </h3>
                              <p className="text-sm text-gray-700">
                              {errorMessage}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* ローディングがtrueのときにローディングインジケーターを表示 */}
                      {isLoading ? (
                        <div className="loading-container">
                          <div className="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500" role="status" aria-label="loading">
                            <span className="sr-only">読み込み中</span>
                          </div>
                          <span className="loading-text">作成しています...</span>
                        </div>
                      ): null}
                      <button type="submit" className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none ">作成</button>
                    </div>
                  </form>
        
                </div>
              </div>
            </div>
          </main>
        </body>
      </html>
    </div>
  );
};

export default Register;
