import React, { useEffect, useState } from "react";
import './home.css';
import { useNavigate } from "react-router-dom";
import SideBar from "../sidebar/sidebar";
import Footer from "../footer/footer";
import axios from "axios";


// ユーザータイプを定義
type UserType = {
    profileImage: string;
    displayname: string;
    username: string;
    description: string;
    image: string;
    sports: string;
    position: string;
    job: string;
};
// Movieの  
interface Movie {
    videotitle: string;
    video: File | null; //ファイル転送のため
};

// アーカイブタイプを定義
interface ArchiveType {
    user: UserType;
    archiveId: number;
    username: string;
    title: string;
    subtitle: string;
    context: string;
    movies: Movie[];
    isPrivate: boolean;
    created_at: string;
    formatted_created_at?: string; // オプショナルでフォーマットされた日付を追加
};



  


function Home() {
    /* タイムライン */
    const [timeline, settimeline] = useState<ArchiveType[]>([]);

    // Tokenがなければログイン画面に遷移
    const navigate = useNavigate();


    useEffect(() => {
        // GoogleAdSence広告読み込み
        window.onload = function() {
            window.adsbygoogle = window.adsbygoogle || [];
            window.adsbygoogle.push({});
        };
    
        const token = localStorage.getItem('token');
        if (token) {
            axios.get<ArchiveType[]>('https://playarchive-backend.onrender.com/archive/timeline/', {
                headers: {
                    Authorization: `Token ${token}`
                }
            })
            .then(response => {
                const formattedData = response.data.map((archive) => {
                    return {
                        ...archive,
                        formatted_created_at: new Date(archive.created_at).toLocaleDateString('ja-JP', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                        })
                    };
                });
                settimeline(formattedData); // フォーマットされたデータをステートにセット

            })
            .catch(error => {
                console.error('タイムラインのデータ取得中にエラーが発生しました:', error);
            });
        } else {
            navigate('/login'); // トークンがない場合はログインページにリダイレクト
        }
        
    }, [navigate]);

    // 日付ごとにアーカイブをグループ化する関数
    const groupByDate = (posts: ArchiveType[]): { [date: string]: ArchiveType[] } => {
        const groups = posts.reduce<{ [date: string]: ArchiveType[] }>((acc, post) => {
            // 日付のフォーマットに基づいてキーを取得します（例: '2023年8月1日'）
            const date = post.formatted_created_at || new Date(post.created_at).toLocaleDateString('ja-JP', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            });

            // この日付がまだグループになければ、空の配列を作成します
            if (!acc[date]) {
                acc[date] = [];
            }

            // 現在の投稿をその日付のグループに追加します
            acc[date].push(post);

            return acc;
        }, {});

        return groups;
    };

    const groupedTimeline = groupByDate(timeline);

    return (
        <div className='Home'>
            <SideBar/>
            <div className="w-full pt-10 px-4 sm:px-6 md:px-8 lg:ps-72">
                <header>
                  
                    {/* PlayArchive */}
                    <div className="archive-list">
                        <div className="archive-list-left">
                            <h1>Home</h1>
                        </div>
                    </div>
    

                    <div className="flex justify-center mt-4">
                        <div className="max-w-4xl w-full">
                            <div className="side-line"></div>
                            <div className="timeline-container">
                                {Object.entries(groupedTimeline).map(([date, posts]) => (
                                    <div className="titeline">
                                        <div className="ps-2 my-2 first:mt-0">
                                            <h3 className="text-xs font-medium uppercase text-gray-500">
                                            {date}{/* 日付をフォーマットされた形で表示 */}
                                            </h3>
                                        </div>
                                        {posts.map((post) => (
                                        <div key={post.archiveId} className="flex gap-x-3 relative group rounded-lg hover:bg-gray-100">
                                            {/* アイテムをクリックすると詳細ページに移動するリンク（仮のリンク先） */}
                                            <a className="absolute inset-0 z-[1]" href={`/playarchive/${post.user.username}/${post.title}`} aria-label="ArchiveLink"></a>
                                            <div className="relative last:after:hidden after:absolute after:top-0 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 ">
                                                <div className="relative z-10 w-7 h-7 flex justify-center items-center">
                                                    <div className="w-2 h-2 rounded-full bg-white border-2 border-gray-300 group-hover:border-gray-600"></div>
                                                </div>
                                            </div>
                                            <div className="grow p-2 pb-8">
                                                <h3 className="flex gap-x-1.5 font-semibold text-gray-800">
                                                    {/* ここにSVGアイコンを挿入 */}
                                                    {post.title} {/* タイトルを表示 */}
                                                </h3>
                                                <p className="mt-1 text-sm text-gray-600">
                                                    {post.subtitle} {/* サブタイトルや説明文を表示 */}
                                                </p>
                                                {/* ユーザー名とプロフィール画像を表示するボタン */}
                                                <a href={`/profile/${post.user.username}`}>
                                                <button type="button" className="mt-1 -ms-1 p-1 relative z-10 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-white hover:shadow-sm disabled:opacity-50 disabled:pointer-events-none">
                                                    <img className="flex-shrink-0 w-4 h-4 rounded-full" src={post.user.profileImage} alt={post.user.displayname} />
                                                    {post.user.displayname} さんがArchiveを作成しました!
                                                </button>
                                                </a>
                                            </div>
                                        </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                            
                            
                        </div>
                        <div className="parent">
                            <div className="height-line"></div>
                        </div>
                        <div className="home-right">
                            <div className="w-80 ml-4">
                                    {/* サイドバーの広告スペース */}
                                    {/* 広告やその他のコンテンツをここに挿入 */}
                                    <div className="bg-white p-4 shadow rounded-lg mb-4">
                                        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7377479018391056"
                                                crossOrigin="anonymous"></script>
                                        <ins    className="adsbygoogle"
                                                style={{ display: 'inline-block', width: '150px', height: '250px' }}
                                                data-ad-client="ca-pub-7377479018391056"
                                                data-ad-slot="2188694380"
                                                data-ad-format="auto"
                                                data-full-width-responsive="true"></ins>
                                            <script>
                                                (adsbygoogle = window.adsbygoogle || []).push({});
                                            </script>
                                            
                                        </div>
                                    <div className="bg-white p-4 shadow rounded-lg">
                                        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7377479018391056"
                                                crossOrigin="anonymous"></script>
                                        <ins    className="adsbygoogle"
                                                style={{ display: 'inline-block', width: '150px', height: '250px' }}
                                                data-ad-client="ca-pub-7377479018391056"
                                                data-ad-slot="2188694380"
                                                data-ad-format="auto"
                                                data-full-width-responsive="true"></ins>
                                            <script>
                                                (adsbygoogle = window.adsbygoogle || []).push({});
                                            </script>    
                                    </div>
                            </div>
                        </div>
                        
                    
                </div>
                <Footer/>                           
                </header>
            </div>
        </div>
    )
}

export default Home;