import React, {useState} from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './login.css';



function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  // ローディング状態を管理するための状態
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    try {
      // リクエスト前にローディングをtrueに設定
      setLoading(true);
      const response = await axios.post('https://playarchive-backend.onrender.com/accounts/login/',{
        username,
        password,
      });
      // レスポンスからトークンを取り出す
      const token = response.data.token;
      // トークンをローカルストレージに保存する
      localStorage.setItem('token', token);

      // Follow機能のため、usernameをローカルストレージに保存する
      const loggedInUsername = response.data.username; 
      localStorage.setItem('username', loggedInUsername);
      
      alert('ログインしました');
      navigate('/playarchive');
    }catch (error) {
      if(axios.isAxiosError(error) && error.response){
        // バックエンドからエラーメッセージを取得
        setErrorMessage(error.response.data.detail || 'ユーザーIDまたはパスワードが間違っています');
      } else {
         setErrorMessage('ログインエラーが発生しました')
      }
      
    } finally {
      // リクエスト完了後にローディングをfalseに設定
      setLoading(false);
    }
   
  };

  return (
    <div className="login h-full min-h-screen bg-gray-100">
      <html className="h-full">
        <body className="bg-gray-100 flex h-full items-center py-16">
          <main className="w-full max-w-md mx-auto p-6">
            <div className="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm">
              <div className="p-4 sm:p-7">
                <div className="text-center">
                  <h1 className="block text-2xl font-bold text-gray-800">サインイン</h1>
                  <p className="mt-2 text-sm text-gray-600 ">
                    アカウントお持ちですか？
                    <a className="text-blue-600 decoration-2 hover:underline font-medium" href="/register">
                      アカウント作成
                    </a>
                  </p>
                </div>

                <div className="mt-5">
                  {/* ここから処理 */}
                  <form onSubmit={handleSubmit}>
                    <div className="grid gap-y-4">

                      <div>
                        <label htmlFor="username" className="block text-sm mb-2 ">ユーザーID</label>
                        <div className="relative">
                          <input type="text" id="username" value={username} onChange={(e) => setUsername(e.target.value)} className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" required aria-describedby="email-error"/>
                          <div className="hidden absolute inset-y-0 end-0 flex items-center pointer-events-none pe-3">
                            <svg className="h-5 w-5 text-red-500" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" aria-hidden="true">
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                            </svg>
                          </div>
                        </div>
                        {/* エラー文 */}
                        <p className="hidden text-xs text-red-600 mt-2" id="email-error">Please include a valid email address so we can get back to you</p>
                      </div>

                      <div>
                        <div className="flex justify-between items-center">
                          <label htmlFor="password" className="block text-sm mb-2">パスワード</label>
                          <a className="text-sm text-blue-600 decoration-2 hover:underline font-medium" href="/passwordforget">パスワードをお忘れですか?</a>
                        </div>
                        <div className="relative">
                          <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)}className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" required aria-describedby="password-error"/>
                          <div className="hidden absolute inset-y-0 end-0 flex items-center pointer-events-none pe-3">
                            <svg className="h-5 w-5 text-red-500" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" aria-hidden="true">
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                            </svg>
                          </div>
                        </div>
                        <p className="hidden text-xs text-red-600 mt-2" id="password-error">8+ characters required</p>
                      </div>

                      <div className="flex items-center">
                        
                     
                      </div>
                      {errorMessage && <p>{errorMessage}</p>}
                      {/* ローディングがtrueのときにローディングインジケーターを表示 */}
                      {isLoading ? (
                        <div className="loading-container">
                          <div className="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500" role="status" aria-label="loading">
                            <span className="sr-only">読み込み中</span>
                          </div>
                          <span className="loading-text">ログインします...</span>
                        </div>
                      ): null}
                      <button type="submit" className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none ">サインイン</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </main>
        </body>
      </html>
    </div>
  );
};


export default Login;