import React, {useState} from 'react';
import './contact.css';
import Header from '../header/header';
import Footer from '../footer/footer';
import axios from "axios";


function Contact() {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            // axios.post の呼び出し
            const response = await axios.post('https://playarchive-backend.onrender.com/homepage/contact/', formData);
                
            if (response.status === 200) {
                window.alert('送信しました！');
            } else {
                // サーバーからの応答が200以外の場合
                alert('送信に失敗しました');
            }
        } catch (err) {
            // ネットワークエラーまたはサーバーが200以外のステータスコードを返した場合
            alert('送信に失敗しました');
        }
    };
    

    return(
        <div className='contact'>
            <Header />
                <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
                <div className="mx-auto max-w-2xl">
                    <div className="text-center">
                    <h2 className="text-xl text-gray-800 font-bold sm:text-3xl">
                        お問い合わせ
                    </h2>
                    </div>

                    <div className="mt-5 p-4 relative z-10 bg-white border rounded-xl sm:mt-10 md:p-10">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4 sm:mb-8">
                            <label htmlFor="hs-feedback-post-comment-name-1" className="block mb-2 text-sm font-medium ">お名前</label>
                            <input type="text" id="hs-feedback-post-comment-name-1" className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" placeholder="お名前"
                            name="name" 
                            value={formData.name} 
                            onChange={handleChange}/>
                        </div>

                        <div className="mb-4 sm:mb-8">
                            <label htmlFor="hs-feedback-post-comment-email-1" className="block mb-2 text-sm font-medium ">メールアドレス</label>
                            <input type="email" id="hs-feedback-post-comment-email-1" className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
                             name="email"
                             value={formData.email}
                             onChange={handleChange}placeholder="メールアドレス"/>
                        </div>

                        <div>
                            <label htmlFor="hs-feedback-post-comment-textarea-1" className="block mb-2 text-sm font-medium ">お問い合わせ内容</label>
                            <div className="mt-1">
                                <textarea id="hs-feedback-post-comment-textarea-1" className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    placeholder="お問い合わせ内容"/>
                            </div>
                        </div>

                        <div className="mt-6 grid">
                            <button type="submit" className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none ">送信</button>
                        </div>
                    </form>
                    </div>
                </div>
                </div>
            <Footer />
        </div>
    );
}
export default Contact;