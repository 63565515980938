import React, { useState } from 'react';
import axios from 'axios';
import SideBar from '../sidebar/sidebar';
import Footer from '../footer/footer';
import './archivesearch.css';
import { Link } from "react-router-dom";

// ユーザータイプを定義
type UserType = {
    profileImage: string;
    displayname: string;
    username: string;
    description: string;
    image: string;
    sports: string;
    position: string;
    job: string;
};

interface SearchResult {
    archives: Array<{
      title: string;
      subtitle: string;
      context: string;
      isPrivate: boolean;
      formatted_created_at?: string;
      user:  UserType;
    }>;
    
    users: Array<{
      profileImage: string;
      displayname: string;
      username: string;
      description: string;
      
    }>;
}
  
  
  function SearchComponent() {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState<SearchResult>({ archives: [], users: [] });
  
    const handleKeyPress = async (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          e.preventDefault(); // フォームのデフォルト送信を防ぐ
          try {
            const response = await axios.get('https://playarchive-backend.onrender.com/archive/search/', {
              headers: { 'Authorization': `Token ${localStorage.getItem('token')}` },
              params: { search: searchQuery }
            });
      
            // 検索結果のステートを更新
            setSearchResults({
              archives: response.data.archives || [],
              users: response.data.users || []
            });
          } catch (error) {
            alert('検索に失敗しました')
          }
        }
    };
    
    return (
      <div className='Search'>
        <SideBar/>
        <div className="w-full pt-10 px-4 sm:px-6 md:px-8 lg:ps-72">
            <header>
                <div className='search-form'>
                <input
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyPress={handleKeyPress} // Enterキーが押されたときに検索を実行
                />
                </div>
                <div className="flex justify-center mt-4">
                        <div className="max-w-4xl w-full">
                                <div className='search-left'>
                                    <div className='serach-left-archive'>
                                        <h1>アーカイブ:</h1>
                                    </div>
                                    <div className='side-line'></div>
                                    <div className='mt-6 space-y-4'>
                                            {searchResults.archives.length > 0 ? (
                                                <ul>
                                                    {searchResults.archives.map((archive, index) => (
                                                        <li key={index} className="archive-list-item">
                                                            <div className="item-container">
                                                                <div className="item-left-section">
                                                                    <img src={archive.user.profileImage}  alt={archive.user.displayname} className="item-icon" />
                                                                    <div className="item-text-info">
                                                                        <Link to={`/playarchive/${archive.user.username}/${archive.title}`} className="text-blue-600 hover:underline">
                                                                            <div className="item-text">{archive.title}</div>
                                                                        </Link>
                                                                        <div className="item-text">{archive.user.displayname}</div>
                                                                        
                                                                    </div>
                                                                </div>
                                                                <div className="item-right-section">
                                                                    <div className="item-text">{archive.isPrivate}</div>
                                                                    <div className="item-text">{archive.formatted_created_at}</div>
                                                                </div>
                                                                <button className="item-action-button">
                                                                    {/* アイコン SVG または他の要素をここに挿入 */}
                                                                </button>
                                                                </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <p>アーカイブを検索しよう！！</p>
                                            )}
                                        
                                    </div>
                            </div>
                        </div>  
                        <div className="parent">
                            <div className="height-line-search"></div>
                        </div>
                        <div className="w-80 ml-4">
                            <div className='search-right'>
                                <div className='user-search'>
                                    <h2>ユーザー結果:</h2>
                                </div>
                                <div className='side-line-user'></div>
                                <div className="user-search-container">
                                    {searchResults.users.length > 0 ? (
                                        <ul className="user-search-list">
                                        {searchResults.users.map((user, index) => (
                                            <li key={index}>
                                                <a href={`/profile/${user.username}`}>
                                                    <div className='user-search-top'>  
                                                        <div className="hs-tooltip inline-block [--trigger:hover] sm:[--placement:right]">
                                                            <div className="hs-tooltip-toggle max-w-xs p-3 flex items-center gap-x-3 bg-white border border-gray-200 rounded-xl shadow-sm">
                                                                <img className="inline-block h-9 w-9 rounded-full" src={`https://playarchive-backend.onrender.com${user.profileImage}`}  alt="User Profile"/>
                                                                <div className="grow">
                                                                    <h4 className="font-semibold text-sm text-gray-800">
                                                                        @{user.username}
                                                                    </h4>
                                                                    <p className="text-sm text-gray-800 md:text-gray-500">
                                                                        {user.displayname}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                        ))}
                                        </ul>
                                    ) : (
                                        <p>ユーザーを検索しよう！</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                <Footer/>
            </header>
        </div>
      </div>
    );
  }
  

export default SearchComponent;

