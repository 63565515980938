import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
// mp4をインポートする場合react-app-env.d.tsに変更を加える必要あり
import "./playarchivedetail.css";
import axios from "axios";
import SideBar from "../sidebar/sidebar";
import { Link } from "react-router-dom";

// ムービータイプを定義
interface Movie {
    title: string;
    video: string; // URLを想定
    day: string; // 日付のフォーマットを確認してください
}
  
// ユーザータイプを定義
type UserType = {
    profileImage: string;
    displayname: string;
    username: string;
};
//　XXXX: 定義の仕方見直しひつようあり
// アーカイブタイプを定義
// アーカイブタイプを定義
interface ArchiveType {
    user: UserType;
    archiveId: number;
    username: string;
    title: string;
    subtitle: string;
    context: string;
    movies: Movie[];
    isPrivate: boolean;
    created_at: string;
};

function PlayArchiveDetail() {
    const [archive,setArchive] = useState<ArchiveType | null>(null);
    // URLパラメータからユーザー名を取得
    const { username } = useParams();
    const { title } = useParams<{ title: string }>();
    const navigate = useNavigate();
    // 認証ユーザーの情報取得
    const [currentUser, setCurrentUser] = useState<UserType | null>(null);

    /* アーカイブのデータ取得 */
    useEffect(() => {
        // トークンを取得
        const token = localStorage.getItem('token');
        if(!token) {
            navigate('/login');
            return;
        }
        // ヘッダーにトークンを含める
        const config = {
            headers: { 
                Authorization: `Token ${token}` 
            }
        };
        // 認証ユーザーの情報を取得
        const fetchCurrentUser = async () => {
            try {
                const userResponse = await axios.get(`https://playarchive-backend.onrender.com/accounts/profile/`, config);
                setCurrentUser(userResponse.data);
            } catch (error) {
                console.error('ユーザー情報の取得に失敗しました', error);
            }
        };

        // アーカイブを取得
        const fetchArchive = async () => {
            try {
                const archivesUrl = username 
                ? `https://playarchive-backend.onrender.com/archive/${username}/archives/${title}/`
                : `https://playarchive-backend.onrender.com/archive/user/archives/${title}/`;
        
                const archiveResponse = await axios.get(archivesUrl, config);                
                setArchive(archiveResponse.data);
                
            } catch (error) {
               
            }
        };
        
        fetchCurrentUser();
        fetchArchive();
    },[username,title,navigate]);

    // 認証ユーザーがアーカイブの作成者であるかの確認
    const isCreator = currentUser && archive && currentUser.username === archive.user.username;

    // 削除ボタンを押した時の処理
    const handleDelete = async () => {
        // 削除確認
        const confirmDelete = window.confirm('本当に削除しますか？');
        // キャンセルした場合は何もしない
        if(!confirmDelete) {
            return;
        }

        // トークンを取得
        const token = localStorage.getItem('token');
        if(!token) {
            navigate('/login');
            return;
        }

        try {
            await axios.delete(`https://playarchive-backend.onrender.com/archive/user/archives/${title}/delete/`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            });

            // 削除成功のアラートを表示
            window.alert('削除しました');
            navigate('/playarchive');
        } catch (error) {

        }
    };

    if(!archive) {
        return <div>アーカイブがありません</div>;
    }
  

    return(
        <div className="playarchivedetail">
            <SideBar/>
            <div className="w-full pt-10 px-4 sm:px-6 md:px-8 lg:ps-72">
                <header>
                        <div className="playarchivedetail-archive-item">
                            <div className="playarchivedetail">
                                <div className="playarchivedetail-top">
                                    <div className="playarchivedetail-user">
                                        <img src={`${archive.user.profileImage}`} alt="User Iimage"/>
                                        <p>{archive.user.displayname}</p>
                                    </div>
                                    <div className="playarchivedetail-title">
                                        <h1>{archive.title}</h1>
                                        <span className={`playarchive-status ${archive.isPrivate ? 'private' : 'public'}`}>
                                            {archive.isPrivate ? 'Private' : 'Public'}        
                                        </span>
                                    </div> 
                                </div>
                                {isCreator && (
                                <div className="Link">
                                    <div className="playarchivedetail-edit">
                                        {/*　下線を取り除くためstyleを使用 */}
                                        <Link to={`/playarchive/${archive.title}/edit`} style={{ textDecoration: 'none', color: 'white' }}>
                                            <h2 className="playarchive-title-edit">編集する</h2>
                                        </Link>
                                    </div>
                                    <div className="playarchivedetail-delete">
                                        <button onClick={handleDelete} className="playarchive-title">削除する</button>
                                    </div>
                                </div>
                                )}
                                {/* ここからmoviesを表示するコード */}
                                <div className="playarchivedetail-movies">
                                    {archive.movies.map((movie) => (
                                        <div key={movie.title} className="playarchivedetail-movie-item">
                                            <div className="playarchivedetail-movie-header">
                                                <img src={archive.user.profileImage} alt="UserImage" className="user-image"/>
                                                <div className="movie-details">
                                                <h5 className="movie-title">{movie.title}</h5>
                                                <p className="movie-date">{movie.day}</p>
                                                </div>
                                                <button className="movie-play-button">▶️</button>
                                            </div>
                                        {/** ここでサイズの指定が必要**/}
                                        <video width="100" controls className="movie-video">
                                            <source src={movie.video} type="video/mp4" />
                                            あなたのブラウザはvideoタグをサポートしていません。
                                        </video>
                                        </div>
                                    ))}
                                </div>
                                <div className="playarchivedetail-description">
                                    <h3>{archive.subtitle}</h3>
                                    <p>{archive.context}</p>
                                </div>
                            </div>
                        </div>   
                </header>
            </div>
        </div>
    )

}

export default PlayArchiveDetail;