import React, { useState } from 'react';
import './header.css';

function Header() {
    const [isScrolled] = useState(true);
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <header className={`header-transparent ${isScrolled ? 'scrolled' : ''}`}>
            <nav>
                <a className="header-link" href='/'>PlayArchive</a>
            </nav>

            

            <div className="hamburger-menu" onClick={toggleMenu}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>

            <div className={`header-right ${menuOpen ? 'open' : ''}`}>
                <a className="header-link" href='/how-to-use'>使い方</a>
                <a className="header-link" href='/register'>アカウント作成</a>
                <a className="header-link" href='/login'>ログイン</a>
            </div>
            
            <a className="sign-in-button" href='/register'>
                アカウント作成
            </a>
            <a className="sign-in-button" href='/login'>
                ログイン
            </a>

        </header>
    );
}


export default Header;
