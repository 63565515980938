// VerifyEmail コンポーネント
import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const VerifyEmail = () => {
  const { token } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      axios.get(`https://playarchive-backend.onrender.com/accounts/verify/${token}`)
        
        .then(() => {
          alert('メールアドレスが認証されました。ログインしてください。');
          navigate('/login');
        })
        .catch((error) => {
          alert('認証に失敗しました。もう一度やり直してください。')
          // エラーメッセージや他の処理
        });
        
    }
  }, [token, navigate]);

  // レンダリングする内容（ローディングインジケーターなど）
  return <div>メールアドレスの認証を行っています...</div>;
};

export default VerifyEmail;

