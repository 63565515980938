import React, { useState,useEffect, useContext  } from "react"; 
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import "./createarchive.css";
import SideBar from "../sidebar/sidebar";
import { FaUnlockKeyhole } from "react-icons/fa6";
// 例として、ユーザーIDを取得するためのAuthContextがあるとします。
const AuthContext = React.createContext({ userid: '' });

// APIを接続するためにinterfaceを定義
// ムービータイプを定義
interface Movie {
    videotitle: string;
    video: File | null; //ファイル転送のため
};

//　XXXX: 定義の仕方見直しひつようあり
// アーカイブタイプを定義
interface ArchiveType {
    userid: string;
    title: string;
    subtitle: string;
    context: string;
    movies: Movie[];
    isPrivate: boolean;
};

function CreateArchive() {
    const { userid } = useContext(AuthContext);
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [archive, setArchive] = useState<ArchiveType>({
        userid: userid, // ログインしているユーザーのIDを設定
        title: '',
        subtitle: '',
        context: '',
        movies: [], // ここは空の配列としてOKですが、Movie[]型になっていることを確認してください
        isPrivate: false, // デフォルトはPublicに設定
    });

    useEffect(() => {
        // ユーザー認証のチェック
        const token = localStorage.getItem('token');
        if (!token) {
          // トークンが存在しない場合はログインページにリダイレクト
          navigate('/login');
        }
    }, [navigate]);

    // ローディング状態を管理するための状態
    const [isLoading, setLoading] = useState(false);
    

    //　送信処理
    //　送信処理
    //　送信処理
    //　送信処理
    //　送信処理

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
    
        const formData = new FormData();
    
        formData.append('title', archive.title);
        formData.append('subtitle', archive.subtitle);
        formData.append('context', archive.context);
        formData.append('isPrivate', archive.isPrivate ? 'true' : 'false');

        archive.movies.forEach((movie, index) => {
            formData.append(`movies[${index}]title`, movie.videotitle);
            if (movie.video) {
                formData.append(`movies[${index}]video`, movie.video);
            }
        });
    
        try {
            // リクエスト前にローディングをtrueに設定
            setLoading(true);
            // トークンの取得
            const token = localStorage.getItem('token');
            if (!token) {
          
                navigate('/login');
                // ここでログインページにリダイレクトなどの処理を行う
                return;
            } 
    
            // リクエストヘッダーにトークンを設定
            await axios.post('https://playarchive-backend.onrender.com/archive/create/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Token ${token}` // トークンをヘッダーに追加
                }
            });
            // 成功のアラートを表示
            window.alert('アーカイブを作成しました！');
            navigate('/playarchive');
            
        } catch (error) {
            if (axios.isAxiosError(error) && error.response && error.response.data) {
                // エラーメッセージがオブジェクトであることを前提とする
                const errorResponse = error.response.data;

                // 'non_field_errors' のエラーメッセージをチェック
                if (errorResponse.non_field_errors && errorResponse.non_field_errors.length > 0) {
                    setErrorMessage(errorResponse.non_field_errors[0]);
                } 
    
                // 'movies' キーのエラーメッセージをチェック
                else if (errorResponse.movies && errorResponse.movies.length > 0) {
                    // 'movies' 配列の最初のオブジェクトから 'video' キーのエラーメッセージを取得
                    const movieErrors = errorResponse.movies[0];
                    if (movieErrors.video && movieErrors.video.length > 0) {
                        setErrorMessage(movieErrors.video[0] || movieErrors.video[0]);
                    }
                } else {
                    setErrorMessage('アーカイブの作成に失敗しました。');
                }
            } else {
                // 予期せぬエラーが発生した場合のメッセージ
                setErrorMessage('予期せぬエラーが発生しました。');
            }
        } finally {
            // リクエスト完了後にローディングをfalseに設定
            setLoading(false);
        }
    };
    

    // Movieの設定上必要な処理
    const handleMovieChange = (index: number, field: keyof Movie, value: any) => {
        const newMovies = [...archive.movies];
        newMovies[index] = { ...newMovies[index], [field]: value };
        setArchive(prevState => ({ ...prevState, movies: newMovies }));
    };

    // 動画を削除する関数
    const handleDeleteMovie = (index: number) => {
        setArchive(prevState => {
            const newMovies = prevState.movies.filter((_, i) => i !== index);
            return { ...prevState, movies: newMovies };
        });
    };

    return(
        <div className="createarchive">
            <SideBar/>
            <div className="w-full pt-10 px-4 sm:px-6 md:px-8 lg:ps-72">
            <div className="main-form">
                <header>
                
                    <div className="createarchive-title">
                        <h1>アーカイブを作成する</h1>
                    </div>
                    <div className="createarchive-form">
                        <form onSubmit={handleSubmit}>
                            <div className="createarchive-form-title">
                                <div className="createarchive-form-title-top">
                                    <p>タイトル名　  (他のアーカイブと同じタイトルはつけられません)</p>
                                    <div className="text-count">
                                        {archive.title.length} / 20 文字
                                    </div>
                                </div>
                                <input 
                                    type="text" 
                                    value={archive.title} 
                                    onChange={e => setArchive(prevState => ({ ...prevState, title: e.target.value }))}
                                    maxLength={20}
                                />
                            </div>
                            <div className="createarchive-form-subtitle">
                                <div className="createarchive-form-title-top">
                                    <label>簡単な説明 </label>
                                    <div className="text-count">
                                            {archive.subtitle.length} / 20 文字
                                    </div>
                                </div>
                                
                                <input 
                                    type="text" 
                                    value={archive.subtitle} 
                                    onChange={e => setArchive(prevState => ({ ...prevState, subtitle: e.target.value }))}
                                    maxLength={20}
                                />
                            </div>
                            <div className="createarchive-form-description">
                                <div className="createarchive-form-title-top">
                                        <label>本文 </label>
                                        <div className="text-count">
                                                {archive.context.length} / 200 文字
                                        </div>
                                </div>

                                
                                <textarea 
                                    value={archive.context} 
                                    onChange={e => setArchive(prevState => ({ ...prevState, context: e.target.value }))}
                                    maxLength={200}
                                ></textarea>
                            </div>

                            <div className="createarchive-form-movie">
                                <h3>Movies</h3>
                                {archive.movies.map((movie, index) => (
                                    <div key={index}>
                                        <div>
                                            <label>動画タイトル </label>
                                            <input 
                                                type="text" 
                                                value={movie.videotitle} 
                                                onChange={e => handleMovieChange(index, 'videotitle', e.target.value)}
                                            />
                                        </div>
                                        <div>
                                            <label>ビデオ: </label>
                                            <input 
                                                type="file" 
                                                onChange={e => handleMovieChange(index, 'video', e.target.files?.[0] || null)}
                                            />
                                        </div>
                                        <button type="button"className="delete-button" onClick={() => handleDeleteMovie(index)}>
                                            動画を削除する
                                        </button>
                                    </div>
                                ))}
                                <button 
                                    className="add-button" 
                                    type="button" 
                                    onClick={() => {
                                        if (archive.movies.length < 5) {
                                            setArchive(prevState => ({
                                                ...prevState,
                                                movies: [...prevState.movies, { videotitle: '', video: null }]
                                            }));
                                        } else {
                                            // ユーザーにアラートで通知
                                            alert('動画は最大5つまでです。');
                                        }
                                    }}
                                >
                                    動画を追加する
                                </button>
                            </div>
                            <div className="createarchive-form-private">
                                    <FaUnlockKeyhole />
                                    <label>非公開にする</label>
                                    <input 
                                        type="checkbox" 
                                        checked={archive.isPrivate} // 'Private' を 'isPrivate' に変更
                                        onChange={e => setArchive(prevState => ({ ...prevState, isPrivate: e.target.checked }))}
                                    />
                            </div>
                            {errorMessage && (
                    
                                <div className="bg-red-50 border-s-4 border-red-500 p-4" role="alert">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            
                                            <span className="inline-flex justify-center items-center w-8 h-8 rounded-full border-4 border-red-100 bg-red-200 text-red-800">
                                            <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
                                            </span>
                                    
                                        </div>
                                        <div className="ms-3">
                                            <h3 className="text-gray-800 font-semibold">
                                            エラーが発生しました🤯
                                            </h3>
                                            <p className="text-sm text-gray-700">
                                            {errorMessage}
                                            </p>
                                        </div>
                                </div>
                            </div>
                            )}
                            {/* ローディングがtrueのときにローディングインジケーターを表示 */}
                            {isLoading ? (
                                <div className="loading-container">
                                <div className="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500" role="status" aria-label="loading">
                                    <span className="sr-only">読み込み中</span>
                                </div>
                                <span className="loading-text">作成中...</span>
                                </div>
                            ): null}
                            <div className="button-container">
                                <button className="create-button" type="submit">作成する</button>
                            </div>

                        </form>
                    </div>
                

                </header>
                </div>
            </div>
            
            

        </div>
    )
}

export default CreateArchive;