import React,{ useRef,useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './profileedit.css'; // Make sure to create a corresponding CSS file for styling
import axios from 'axios';


function ProfileEdit() {
    // ファイルを参照するためのref
    const navigate = useNavigate();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [errorMessage, setErrorMessage] = useState('');
    // 写真の処理
    // 選択された画像のプレビューURLを保持
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    // 新しい画像が選択されたかどうかのステート
    const [newImageSelected, setNewImageSelected] = useState(false);

    // ローディング状態を管理するための状態
    const [isLoading, setLoading] = useState(false);

    // データの取得
    useEffect(() => {
        const fetchUserData = async () => {
            const token = localStorage.getItem('token');

            if (!token) {
                navigate('/login');
                return;
            }

            try {
                // Data取得
                const response = await axios.get('https://playarchive-backend.onrender.com/accounts/profile/edit/',{
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                });
                // データーをセット
                setUserData(response.data);

                // 画像URLが存在する場合、selectedImageもセットします
                if (response.data.profileImage) {
                setSelectedImage(response.data.profileImage);
                // 以前の画像URLを別のステートに保存するか、userDataに含める
                setUserData(prevUserData => ({ ...prevUserData, profileImage: response.data.profileImage }));
                }
            } catch (error) {
                alert('データの取得に失敗しました')
            }
        };
        fetchUserData();
    }, [navigate]);

    // ファイル選択ダイアログを直接開かなくてもファイルを選択できるようになります
    const handleButtonClick = () => {
        // `fileInputRef.current`が存在することを確認
        if (fileInputRef.current) {
        fileInputRef.current.click();
        }
    };

    // ファイルが選択されたとき
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // 選択されたファイルが存在するか確認します。   
            if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            setUserData({ ...userData, profileImage: file });
            // URL.createObjectURLを使用してファイルのプレビューURLを生成し、s
            // etSelectedImageで状態に設定
            setSelectedImage(URL.createObjectURL(file));
            setNewImageSelected(true); // 新しい画像が選択された
        }
    };
    
    

    // プロフィール編集送信の処理
    const [userData, setUserData] = useState({
        username: '',
        displayname: '',
        email: '',
        context: '',
        profileImage: null as File | null, // ここを変更
        sports: '',
        position: '',
        job: ''
    });    

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setUserData({...userData,[event.target.name]: event.target.value});

    };

    // 送信処理
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const file = fileInputRef.current?.files?.[0] || null;
        try {
            await updateUser(userData, file);

            alert('プロファイルが更新されました！');
            navigate('/profile');
        } catch (error) {
            if (axios.isAxiosError(error)) {
                let message = '更新に失敗しました。';
                if (error.response?.data) {
                    // 'data'がオブジェクトか文字列かによって処理が異なります
                    const errors = error.response.data;
                    // エラーメッセージが複数のフィールドに渡っている場合、
                    // それらを組み合わせて一つのエラーメッセージを作成します。
                    if (typeof errors === 'object' && errors !== null) {
                        message = Object.values(errors)
                            .map((e) => Array.isArray(e) ? e.join(' ') : e)
                            .join(' ');
                    } else if (typeof errors === 'string') {
                        message = errors;
                    }
                }
                setErrorMessage(message);
            } else {
                // axios以外のエラーの場合
                setErrorMessage('更新に失敗しました。もう一度お試しください。');
            } 
        } finally {
            // リクエスト完了後にローディングをfalseに設定
            setLoading(false);
        }
    };
    
    const updateUser = async (userData: any, imageFile: File | null) => {
        const token = localStorage.getItem('token');
        if (!token) {
            alert('トークンが存在しません。');
            return;
        }
        
        const formData = new FormData();
        Object.entries(userData).forEach(([key, value]) => {
            if (key !== 'profileImage') {
                if (value instanceof Blob) {
                    formData.append(key, value);
                } else if (typeof value === 'string') {
                    formData.append(key, value);
                } else {
                    formData.append(key, JSON.stringify(value));
                }
            }
        });
    
        if (newImageSelected && imageFile instanceof File) {
            formData.append('profileImage', imageFile);
        }
    
        try {
            const response = await axios.patch('https://playarchive-backend.onrender.com/accounts/profile/edit/', formData, {
                headers: {
                    'Authorization': `Token ${token}`,
                },
            });
            return response;
        } catch (err) {
            // ここでエラーを再スローして、呼び出し元でキャッチできるようにします。
            throw err;
        }
    };
    

    return (
        <div className="profileedit">
            <div className="max-w-4xl px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">

                <div className="bg-white rounded-xl shadow p-4 sm:p-7 ">
                    <div className="mb-8">
                        <h2 className="text-xl font-bold text-gray-800 ">
                            プロフィール編集
                        </h2>
                        <p className="text-sm text-gray-600 ">
                            Manage your name, password and account settings.
                        </p>
                    </div>

                    <form onSubmit={handleSubmit}>

                        <div className="grid sm:grid-cols-12 gap-2 sm:gap-6">
                            <div className="sm:col-span-3">
                            <label className="inline-block text-sm text-gray-800 mt-2.5 ">
                                
                            </label>
                            </div>

                            <div className="sm:col-span-9">
                                <div className="flex items-center gap-5">
                                    {selectedImage && (
                                        <img
                                        src={selectedImage}
                                        alt="Selected"
                                        className="inline-block mt-2 h-16 w-16 rounded-full"
                                        />
                                    )}
                                    <div className="flex gap-x-2">
                                    <div>
                                        <button
                                        type="button"
                                        className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
                                        onClick={handleButtonClick}
                                        >
                                        {/* SVG and text here */}
                                        Upload photo
                                        </button>
                                    </div>
                                    </div>
                                </div>
                                <input
                                    ref={fileInputRef}
                                    type="file"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }} // 非表示にする
                                />
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="af-account-full-name" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                    UserID
                                </label>
                                <div className="hs-tooltip inline-block">
                                    <span className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible w-40 text-center z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm" role="tooltip">
                                    Displayed on public forums, such as Preline
                                    </span>
                                </div>
                            </div>


                            <div className="sm:col-span-9">
                                <div className="sm:flex">
                                    <input id="af-account-full-name" type="text" name="username" value={userData.username} onChange={handleChange} className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" placeholder="userId"/>
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="af-account-email" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                    Email
                                </label>
                            </div>
                

                            <div className="sm:col-span-9">
                                <input id="af-account-email" type="email" name="email" value={userData.email} onChange={handleChange} className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " placeholder="maria@site.com"/>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="af-account-full-name" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                    名前
                                </label>
                                <div className="hs-tooltip inline-block">
                                
                                    <span className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible w-40 text-center z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm " role="tooltip">
                                    Displayed on public forums, such as Preline
                                    </span>
                                </div>
                            </div>

                            <div className="sm:col-span-9">
                                <div className="sm:flex">
                                    <input id="af-account-full-name" type="text" name="displayname" value={userData.displayname} onChange={handleChange} className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " placeholder="名前"/>
                                </div>
                            </div>


                            <div className="sm:col-span-3">
                                <label htmlFor="af-account-bio" className="inline-block text-sm text-gray-800 mt-2.5">
                                    自己紹介
                                </label>
                            </div>
                            <div className="sm:col-span-9">
                                <div className="sm:flex">
                                    <textarea 
                                        id="af-account-full-name" 
                                        name="context" 
                                        value={userData.context} 
                                        onChange={handleChange}
                                        className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
                                        placeholder="自己紹介"
                                    />
                                    <div className="text-count">
                                        {userData.context.length} / 150
                                    </div>
                                </div>
                            </div>


                            <div className="sm:col-span-3">
                                <label htmlFor="af-account-full-name" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                    スポーツ
                                </label>
                                <div className="hs-tooltip inline-block">
                                
                                    <span className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible w-40 text-center z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm " role="tooltip">
                                    Displayed on public forums, such as Preline
                                    </span>
                                </div>
                            </div>

                            <div className="sm:col-span-9">
                                <div className="sm:flex">
                                    <input id="af-account-full-name" type="text" name="sports" value={userData.sports} onChange={handleChange}className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " placeholder="スポーツ"/>
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="af-account-full-name" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                    ポジション
                                </label>
                                <div className="hs-tooltip inline-block">
                                
                                    <span className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible w-40 text-center z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm" role="tooltip">
                                    Displayed on public forums, such as Preline
                                    </span>
                                </div>
                            </div>

                            <div className="sm:col-span-9">
                                <div className="sm:flex">
                                    <input id="af-account-full-name" type="text"name="position" value={userData.position} onChange={handleChange} className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " placeholder="ポジション"/>
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="af-account-full-name" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                    職業
                                </label>
                                <div className="hs-tooltip inline-block">
                                
                                    <span className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible w-40 text-center z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm " role="tooltip">
                                    Displayed on public forums, such as Preline
                                    </span>
                                </div>
                            </div>

                            <div className="sm:col-span-9">
                                <div className="sm:flex">
                                    <input id="af-account-full-name"name="job" value={userData.job} onChange={handleChange} type="text" className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " placeholder="職業"/>
                                </div>
                            </div>
                        </div>
                        {errorMessage && (
                            <div className="bg-red-50 border-s-4 border-red-500 p-4 " role="alert">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                    
                                    <span className="inline-flex justify-center items-center w-8 h-8 rounded-full border-4 border-red-100 bg-red-200 text-red-800 https://playarchive-backend.onrender.com/bg-red-800 ">
                                        <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
                                    </span>
                    
                                    </div>
                                    <div className="ms-3">
                                    <h3 className="text-gray-800 font-semibold">
                                        注意
                                    </h3>
                                    <p className="text-sm text-gray-700">
                                    {errorMessage}
                                    </p>
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* ローディングがtrueのときにローディングインジケーターを表示 */}
                        {isLoading ? <div className="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500" role="status" aria-label="loading">
                            <span className="sr-only">読み込み中</span>
                        </div>: null}

                        <div className="mt-5 flex justify-end gap-x-2">
                            
                            <button type="submit" className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none">
                            Save changes
                            </button>
                        </div>
                    </form>
                </div>
            </div>
    </div>
    );
}

export default ProfileEdit;

