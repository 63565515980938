import React, { useEffect, useState } from "react";
import { Link, useNavigate  } from "react-router-dom";
import axios from "axios";
import './playarchive.css';
import SideBar from "../sidebar/sidebar";
//　XXXX: 定義の仕方見直しひつようあり
// ムービータイプを定義
interface Movie {
    videotitle: string;
    video: File | null; //ファイル転送のため
};
// アーカイブタイプを定義
interface ArchiveType {
    userid: string;
    title: string;
    subtitle: string;
    context: string;
    movies: Movie[];
    isPrivate: boolean;
    created_at: string;
};



function PlayArchive() {
    const [archives, setArchives] = useState<ArchiveType[]>([]);
    // ページ遷移
    const [currentPage, setCurrentPage] = useState(1);
    // 検索
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    }

    const itemsPerPage = 10;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = archives.slice(indexOfFirstItem, indexOfLastItem);

    const goToNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const hasNextPage = archives.length > indexOfLastItem;

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        // トークンをヘッダーに含める
        const config = {
            headers: { Authorization: `Token ${token}` },
            // 検索クエリをパラメータに含める
            params: { search: searchQuery }
        };

        // ユーザーのアーカイブを取得
        const feachArchives = async () => {
            try{
                const responce = await axios.get('https://playarchive-backend.onrender.com/archive/user/archives/', config);
                setArchives(responce.data);
            } catch (error) {
                console.log(error);
            }
        };

        feachArchives();
    },[navigate, searchQuery]);

    return (
        <div className="body-container"> 
            <div className="playarchive-container">
                <SideBar/>
                    <div className="w-full pt-10 px-4 sm:px-6 md:px-8 lg:ps-72">
                        <header>
                            <div className="playarchive-container-table">
                                {/* Marginの都合上必要改善の余地アリ */}
                                <div className="playarchive-header"></div>
                                <div className="playarchive-top-title">
                                    <div className="playarchive-top-search-create">
                                        <input
                                        type="text"
                                        placeholder="Archiveを検索する"
                                        className="playarchive-search-input-text"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                        />
                                    </div>
                                    <Link to="/createarchive"style={{ textDecoration: 'none', color: 'white' }} className="new-button">New</Link>
                                </div>

                                <ul className="playarchive-list">
                                    {currentItems.map((archive) => (
                                    <li key={archive.userid} className="playarchive-item">
                                        
                                        <div className="playarchive-content">
                                            <div className="playarchive-top">
                                                {/*　下線を取り除くためstyleを使用 */}
                                                <Link to={`/playarchive/${archive.title}`} style={{ textDecoration: 'none', color: 'white' }}>
                                                    <h2 className="playarchive-title">{archive.title}</h2>
                                                </Link>
                                                
                                                <span className={`playarchive-status ${archive.isPrivate ? 'private' : 'public'}`}>
                                                    {archive.isPrivate ? 'Private' : 'Public'}
                                                </span>
            
                                            </div>
                                            
                                            <p className="playarchive-description">{archive.context}</p>
                                            <time className="playarchive-date">{archive.created_at}</time>
                                        </div>
                                    </li>
                                    ))}
                                </ul>
                                <div className="pagination-container">
                                    {hasNextPage && (
                                        <button className="pagination-button" onClick={goToNextPage}>次へ</button>
                                    )}
                                </div>
                            </div>
                        
                    </header>
                </div>
            </div>
       
        </div>
    );

}

export default PlayArchive;