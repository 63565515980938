import React from 'react';
import './howtouse.css';
import Header from '../header/header';
import Footer from '../footer/footer';
import { useNavigate } from 'react-router-dom';
import Descritpion1 from './image/Description1.png';
import Descritpion2 from './image/Description2.png';
import Descritpion3 from './image/Description3.png';

function Howtouse() {

    const navigate = useNavigate();

    const StartButton = () => {
        navigate('/register');
    };

    return(
        <div className='howtouse'>
            <Header/>
                <div className='howtouse-main'>
                    <div className='howtouse-main-title'>
                        <h1>TSUKAIKATA</h1>
                    </div>
                    <div className='howtouse-main-content'>
                        <div className='howtouse-main-content-left'>
                            <img src={Descritpion1} alt="素晴らしい機能" />
                        </div>
                        <div className='howtouse-main-content-right'>
                            <div className='howtouse-main-content-right-title'>
                                <h2>アーカイブ作成で今日のプレーを記録しよう！</h2>
                            </div>
                            <div className='howtouse-main-content-right-context'>
                                <p>まずはアーカイブに今日のプレーや動きを記録することから始めましょうこれがあなたの成長の第一歩です</p>
                                <p>アーカイブに記録すればもう重たい束のノートは必要ありません<br></br>すべてがデジタルでいつでもアクセス可能です</p>
                            </div>
                        </div>
                    </div>
                    <div className='howtouse-main-content'>
                        <div className='howtouse-main-content-left'>
                            <img src={Descritpion2} alt="素晴らしい機能" />
                        </div>
                        <div className='howtouse-main-content-right'>
                            <div className='howtouse-main-content-right-title'>
                                <h2>アーカイブに記録したらすぐに見返せてとても便利</h2>
                            </div>
                            <div className='howtouse-main-content-right-context'>
                                <p>アーカイブに記録した内容はいつでもリスト形式で簡単に見返せます<br></br>記録は整理されているので必要な情報をすぐに見つけることができます。</p>
                            </div>
                        </div>
                    </div>
                    <div className='howtouse-main-content'>
                        <div className='howtouse-main-content-left'>
                            <img src={Descritpion3} alt="素晴らしい機能" />
                        </div>
                        <div className='howtouse-main-content-right'>
                            <div className='howtouse-main-content-right-title'>
                                <h2>他のユーザーの練習やプレーを参考にしてどんどん成長しよう！</h2>
                            </div>
                            <div className='howtouse-main-content-right-context'>
                                <p>PlayArchiveでは、自分の記録を振り返るだけでなく検索機能を使って他のユーザーが公開している練習方法やトレーニング方法も閲覧できます!<br></br>他のユーザーの練習法を参考にしあなたもさらに成長しましょう！</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='start-text'>
                    <h1>それでは実際に登録して使ってみよう！！</h1>
                </div>
                <div className='start-button'>
                    <button onClick={StartButton}>アカウント作成</button>
                </div>
            <Footer/>
        </div>
    );

}

export default Howtouse;