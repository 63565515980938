import React, { useState, useEffect } from 'react';
import './home.css'

import MydescriptionImage from './image/bendy-man-developer-writing-programming-code.gif';
import Header from '../header/header';
import Footer from "../footer/footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import  FirstImage  from './image/FirstImage.png';
import SecondImage from './image/SecondImage.png';
import ThardImage from './image/ThardImage.png';
import { useNavigate } from 'react-router-dom';
import MainImage from './image/HomePage-Main-Image.png';


declare global {
   
    interface Window {
      adsbygoogle: any;
    }
}

function HomePage() {
    // 初期状態をtrueに設定して、最初からtext-contentを表示
    const [isVisible, setIsVisible] = useState(false);
    const [isFeatureVisible, setIsFeatureVisible] = useState(false);
    const [isMainContentVisible, setIsMainContentVisible] = useState(false);
    const handleClick = () => {
        window.location.href = 'https://twitter.com/GIANT_KILLING_0';
    };
    // Login処理
    const navigate = useNavigate();
    useEffect(() => {
        // GoogleAdSence広告読み込み
        window.onload = function() {
            window.adsbygoogle = window.adsbygoogle || [];
            window.adsbygoogle.push({});
        };
          
        const handleScroll = () => {
            const textContentElement = document.querySelector('.text-content') as HTMLElement;
            const mainContent = document.querySelector('.main-content') as HTMLElement;

            const checkVisible = (element: HTMLElement | null) => {
                if (!element) return false;
                const position = element.getBoundingClientRect().top;
                return position < window.innerHeight;
            };

            if (checkVisible(textContentElement)) {
                setIsVisible(true);
            }
            if (checkVisible(mainContent)) {
                setIsMainContentVisible(true);
            }
        };
        const checkVisibility = () => {
            const featureSection = document.querySelector('.feature-section');
            if (featureSection) {
                const rect = featureSection.getBoundingClientRect();
                if (rect.top <= window.innerHeight) {
                    setIsFeatureVisible(true);
                    // 一度表示されたらイベントリスナーを削除
                    window.removeEventListener('scroll', checkVisibility);
                }
            }
        };
        // スクロールイベントにリスナーを登録
        window.addEventListener('scroll', checkVisibility);

        // 初期チェックを行い、ページロード時にセクションがビューポート内にあるか確認
        checkVisibility();

        // スクロールイベントリスナーを追加
        window.addEventListener('scroll', handleScroll);

        return () => {
            // コンポーネントがアンマウントされた時にリスナーを削除
            window.removeEventListener('scroll', checkVisibility);
            // コンポーネントのアンマウント時にイベントリスナーを削除
            window.removeEventListener('scroll', handleScroll);
        };
        
        
    }, []);

    const StartButton = () => {
        navigate('/register');
    };

    
    return(
        
        <div className= "HomePage">
            
            <Header/>
            <div className="top-main">
                <div className="top-main-text">
                    <h1>スポーツ選手向けの記録アプリ</h1>
                    <h1>重たい束のノートを置いて技術の進化をひとつのアプリで</h1>
                    <button onClick={StartButton}>PlayArchiveを始める</button>
                </div>
                <div className="top-main-image">
                    <img src={MainImage} alt="素晴らしい機能" />
                </div>
            </div>
            <div className="home">
                <div className="container">
                    <div className="content">
                        <h1>新しい時代のデジタルノート</h1>
                        <h2>あなたが求めている機能が揃っています</h2>
                        <p>PlayArchiveはスポーツなどであなたの動きを動画で記録しいつでも簡単に振り返る事ができるアプリです。スポーツ活動やトレーニングの記録と分析を支援しパフォーマンスの向上を目指すツールです</p>
                        <button onClick={StartButton}>使い方はこちらを閲覧</button>
                    </div>
                </div>
            </div>
            <div className="main">
                <img src={FirstImage} alt="記録と振り返り" />
                <div className={`text-content ${isVisible ? 'slide-up' : ''}`}>
                    <h2>ノートの文字だけでは分からない</h2>
                    <p>PlayArchiveは従来のノートブックとは一線を画すスポーツ記録ツールです<br></br>文字だけでは伝わりにくい細かな技術的なポイントも、動画であれば一目瞭然<br></br>ノートブックよりも動画で記録することによって貴方の技術的成長をサポートします</p>
                </div>
            </div>
            <div className='background'>
                <h1>デ</h1>
                <h1>イ</h1>
                <h1>ザ</h1>
                <h1>ン</h1>
            </div>
            <div className="feature-section">
                <div className={`feature-content ${isFeatureVisible ? 'slide-up' : ''}`}>
                    <h2>ブックマークやタイムラインなどの機能も豊富</h2>
                    <p>パフォーマンスを磨き上げる多様なツールをあなたの手に。私たちの磨かれたツールが、あなたの才能を磨き、可能性を解き放つお手伝いをします。PlayArchiveの革新的な機能が、目標達成への道筋を照らし出し、あなたのスポーツキャリアを加速させるでしょう。常に挑戦するあなたに最適な、このプラットフォームは、日々の努力を可視化し、新たな高みを目指す旅に、確かな足がかりを提供します。</p>
                </div>
                <div className="feature-image">
                    <img src={SecondImage} alt="記録と振り返り" />
                </div>
            </div>
            <div className='background'>
                <h1>デ</h1>
                <h1>イ</h1>
                <h1>ザ</h1>
                <h1>ン</h1>
            </div>
            <div className="main">
                <img src={ThardImage} alt="記録と振り返り" />
                <div className={`main-content ${isMainContentVisible ? 'slide-up' : ''}`}>
                    <h2>あなただけではなくみんなの練習を見てインスピレーションを</h2>
                    <p>PlayArchiveでは、自己のスキル向上だけにとどまらず、他のユーザーのトレーニングセッションからも学べます。他の選手たちの練習方法を見ることができ、それらを参考に自分自身のトレーニングプランを拡充しましょう。PlayArchiveで、コミュニティの力を借りて、次なるステージへと飛躍しましょう。</p>
                </div>
            </div>
            <div className='background'>
                <h1>デ</h1>
                <h1>イ</h1>
                <h1>ザ</h1>
                <h1>ン</h1>
                <h1>デ</h1>
                <h1>イ</h1>
                <h1>ザ</h1>
                <h1>ン</h1>
               
            </div>
            <div className="descrription-background">
                <div className="description">
                    <div className="description-text">
                        <h1>PlayArchiveの旅が始まります</h1>
                        <p>新たなスポーツ記録のエポックがスタート。この日から、あなたのプレーの全てをデジタルの記憶に留め、洗練された分析ツールでスキルを磨くことができます。私たちは、あなたの毎日のトレーニングと競技会を、より価値あるものに変えるためのプラットフォームを提供します。プレーの瞬間を捉え、成長の旅を共有することを楽しみにしています。</p>
                    </div>
                    <div className="description-button">
                        <button onClick={handleClick}>最新の情報はこちらをご覧ください</button>
                    </div>
                </div>
            </div>
            <div className="mydescription">
                <img src={MydescriptionImage} alt="記録と振り返り" />
                
                <div className="mydescription-text">
                    <h1>スポーツの記録を革新するプラットフォーム</h1>
                    <p>PlayArchiveは、青年によって開発された革新的なプレー記録サービスです。私たちの目標は、選手の技術向上と知識共有をサポートし、スポーツ界全体の発展に貢献することです。多くのアスリートに愛用され、PlayArchiveが皆様の成長に役立てば幸いです。SNSでのシェアも大歓迎です！新しいスポーツ体験の扉が開かれるその日を、どうぞご期待ください。</p>
                    <a href="https://twitter.com/intent/tweet?text=PlayArchiveでスポーツの記録を革新しよう！&url=https://play-archive.com&hashtags=PlayArchive,スポーツ,記録" target="_blank" rel="noreferrer noopener">
                        <FontAwesomeIcon icon={faTwitter} /> Twitterでシェア
                    </a>
                    
                </div>
            </div>
             <Footer></Footer>
        </div>
       
    );
}

export default HomePage;